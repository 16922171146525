<template>
  <div class="card-payment-container flex flex-col gap-4">
    <!-- TODO: Refactor -->
    <div class="currency-input-container w-full">
      <div class="label text-white mb-1">
        You pay with
      </div>
      <div class="input-container bg-slate-900 rounded-lg p-1 w-full transition-all inline-flex min-h-[60px]">
        <input
          :value="depositAmount"
          type="number"
          placeholder="0"
          class="input sm:max-w-full max-w-[calc(58%)] grow relative bg-slate-900"
          @input="depositAmount = $event.target.value"
        >
        <DropdownCurrency
          is-fiat
          is-small
          is-moonpay-filter
          bg="bg-slate-500"
          class="currency flex-1"
          @select="handleChangeCurrency"
        />
      </div>
      <div v-if="errorMessage" class="text-red text-center text-sm mt-4">{{ errorMessage }}</div>
    </div>
    <div class="currency-input-container">
      <div class="label text-white mb-1">
        You get
      </div>
      <div class="input-container bg-slate-900 rounded-lg p-1 w-full transition-all inline-flex min-h-[60px]">
        <input
          :value="depositOutputAmount"
          type="number"
          placeholder="0"
          class="input sm:max-w-full max-w-[calc(58%)] grow relative bg-slate-900"
          @input="depositOutputAmount = $event.target.value"
        >
        <DropdownCurrency
          is-small
          is-moonpay-filter
          bg="bg-slate-500"
          class="currency flex-1"
          @select="handleChangeCurrency"
        />
      </div>
    </div>

    <div class="card-details-options bg-slate-700 px-3 py-1.5 rounded-lg">
      <div class="row flex py-1.5">
        <span class="text-slate-400">
          Provider
        </span>
        <span class="ml-auto">
          <SvgIcon
            icon="moonpay"
            width="80"
            height="16"
          />
        </span>
      </div>
      <div class="row flex py-1.5">
        <span class="text-slate-400">
          Method
        </span>
        <span class="ml-auto">
          <SvgIcon
            icon="mastercard"
            height="24"
            width="24"
            class="icon mx-[8px] overflow-hidden"
          />
          <SvgIcon
            icon="visa"
            height="24"
            width="40"
            class="icon mx-[8px] overflow-hidden"
          />
          <SvgIcon
            icon="applePay"
            height="24"
            width="40"
            class="icon mx-[8px] overflow-hidden"
          />
          <SvgIcon
            icon="googlePay"
            height="24"
            width="80"
            class="icon mx-[8px] overflow-hidden mr-0"
          />
        </span>
      </div>
    </div>

    <WalletTransferOutput
      :tx-amount="depositAmount || 0"
      :fee="fee || 0"
      :output-amount="depositOutputAmount || 0"
      :currency-code="selectedCurrency?.code"
      :currency-code-fiat="selectedCurrencyFiat?.code"
      has-bg
    />

    <RewardAppliedCode
      source="Deposit"
      :code="depositCode"
      />

    <div class="have-crypto text-center w-full">
      Have crypto?
      <ButtonButton
        theme="loadmore"
        size="sm"
        class="font-semibold text-center !text-blue rounded-md underline !p-0"
        @click.prevent="emits('select')"
      >
        Deposit via Crypto
      </ButtonButton>
    </div>

    <ButtonMoonPay
      is-buy-only
      :currency-name="selectedCurrency?.code || 'ETH'"
      :is-disabled="isMoonPayDisabled"
      @start-moonpay-payment="handleStartMoonpayPayment(moonpaySubmissionDetails.address, depositAmount, selectedCurrencyFiat.code, selectedCurrency.code)"
    />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import BigNumber from 'bignumber.js';

import { useUiStore } from '~/store/ui';
import { useBankingStore } from '~/store/banking';
import { useDepositAccountStore } from '@/store/depositAccount';
import { useMoonPay } from '~/composables/useMoonPay';
import { DEPOSIT_ACCOUNT_PROVIDER } from '@/core/deposit-account/provider';
import { useWallet } from '~/composables/useWallet';

const emits = defineEmits(['select',]);

const depositAccountStore = useDepositAccountStore();
const {
  init: initDepositAccountStore,
  add: fetchNewDepositAccount,
} = depositAccountStore;
const {
  accounts,
} = storeToRefs(depositAccountStore);

const bankingStore = useBankingStore();
const {
  balances,
} = storeToRefs(bankingStore);

const uiStore = useUiStore();
const {
  walletModalFundsContext,
} = storeToRefs(uiStore);

const {
  isMoonPayLoading,

  fetchMoonpayBuyFiatQuote,
  handleStartMoonpayPayment,
} = useMoonPay();

const {
  setWalletAddressDataUrl,
} = useWallet();

const depositAmount = ref();
const depositOutputAmount = ref();
const selectedCurrency = ref(null);
const selectedCurrencyFiat = ref(null);
const fee = ref(0);
const canRefetch = ref(true);
const errorMessage = ref('');
const moonpaySubmissionDetails = reactive({
  dataUrl: '',
  address: '',
});

const props = defineProps({
  depositCode: String,
  default: null,
})

const currentBalance = computed(() => balances.value.find(item => item.name === selectedCurrency.value?.code && item.context === walletModalFundsContext.value));
const isMoonPayDisabled = computed(() => {
  return isMoonPayLoading.value
  || (depositAmount.value < selectedCurrencyFiat.value?.minBuyAmount)
  || (depositAmount.value > selectedCurrencyFiat.value?.maxBuyAmount);
});

if (!accounts.value.length) {
  await initDepositAccountStore();
}

/**
 * Option is a currency object
 *     - Fiat = Fiat Currency object from Moonpay
 *     - Crypto = Crypto Currency object from Balances (Metawin)
 */
async function handleChangeCurrency(option) {
  if (option.type === 'fiat') {
    selectedCurrencyFiat.value = option;
    if (depositAmount.value && canRefetch.value && !isMoonPayDisabled.value) {
      await handleMoonpayQuote('fiat');
    }
  } else {
    selectedCurrency.value = option;
    await fetchCryptoCurrencyAddress(currentBalance.value.name, currentBalance.value.config.defaultNetwork);
    if (depositOutputAmount.value && canRefetch.value) {
      await handleMoonpayQuote('crypto');
    }
  }
}

async function handleMoonpayQuote(type) {
  errorMessage.value = '';

  if (
    (type === 'fiat' && new BigNumber(depositAmount.value).toNumber() <= 0)
    || (type === 'crypto' && new BigNumber(depositOutputAmount.value).toNumber() <= 0)
  ) {
    return;
  }

  const response = await fetchMoonpayBuyFiatQuote(
    selectedCurrencyFiat.value?.code,
    selectedCurrency.value?.baseCode || selectedCurrency.value?.code,
    new BigNumber(type === 'fiat' ? depositAmount.value : depositOutputAmount.value).toNumber(),
    type
  );

  if (!response.error) {
    canRefetch.value = false;
    if (type === 'fiat') {
      depositOutputAmount.value = new BigNumber(response.quoteCurrencyAmount).toNumber();
    } else {
      depositAmount.value = new BigNumber(response.baseCurrencyAmount).toNumber();
    }
    fee.value = new BigNumber(response.feeAmount).plus(new BigNumber(response.networkFeeAmount)).toNumber();
  } else {
    errorMessage.value = response?.data?.message || 'Something went wrong please try again.';
  }
}

async function fetchCryptoCurrencyAddress(currencyCode, network) {
  errorMessage.value = '';
  moonpaySubmissionDetails.address = '';
  moonpaySubmissionDetails.dataUrl = '';

  let account = accounts.value.find(a => a.currencyCode === currencyCode && a.network === network);

  if (!account?.address) {
    try {
      account = await fetchNewDepositAccount(currencyCode, DEPOSIT_ACCOUNT_PROVIDER.Fireblocks, network);
    } catch (err) {
      switch (err.message) {
        case 'DepositAccountAlreadyExists':
          errorMessage.value = 'A deposit wallet for the selected currency already exists.';
          break;
        case 'DepositAccountCreationFailed':
          errorMessage.value = 'We have been unable to create your deposit wallet.';
          break;
        default:
          errorMessage.value = 'An unexpected error occurred.';
          break;
      }
    }
  }

  if (account?.address) {
    moonpaySubmissionDetails.address = account.address;
    moonpaySubmissionDetails.dataUrl = setWalletAddressDataUrl(currencyCode, moonpaySubmissionDetails.address);
  }
}

watch(
  () => depositAmount.value,
  async(newVal) => {
    if (newVal && canRefetch.value && !isMoonPayDisabled.value) {
      await handleMoonpayQuote('fiat');
    } else {
      canRefetch.value = true;
    }
    if (isMoonPayDisabled.value) {
      if (depositAmount.value < selectedCurrencyFiat.value?.minBuyAmount) {
        errorMessage.value = `The minimum amount to buy is ${selectedCurrencyFiat.value?.minBuyAmount} ${selectedCurrencyFiat.value?.code}`;
      } else if (depositAmount.value > selectedCurrencyFiat.value?.maxBuyAmount) {
        errorMessage.value = `The maximum amount to buy is ${selectedCurrencyFiat.value?.maxBuyAmount} ${selectedCurrencyFiat.value?.code}`;
      }
    }
  }
);

watch(
  () => depositOutputAmount.value,
  async(newVal) => {
    if (newVal && canRefetch.value) {
      await handleMoonpayQuote('crypto');
    } else {
      canRefetch.value = true;
    }
  }
);
</script>

<style lang="scss" scoped>
.currency {
  min-width: 125px;
}

.input {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }
}
</style>
