<template>
  <div class="messages pt-2">
    <div v-if="txUrl || isDepositRestricted" class="text-center text-sm leading-tight min-h-[16px]">
      <p v-if="isDepositRestricted">
        The country you're connecting from is restricted
      </p>
      <div
        v-else-if="(!showDepositCompleted && (txHash || swapsPending.length > 0)) || isDepositProcessing"
        class="animate-pulsing flex justify-center items-center"
      >
        <span class="inline-block">Pending Deposit</span>
        <a
          v-if="txUrl"
          :href="txUrl"
          class="flex items-center ml-1 uppercase"
          target="_blank"
        >
          <span class="max-w-[60px] text-ellipsis overflow-hidden inline-block">{{ txHash }}</span>
          <span class="icon-ico-link-external relative text-sm"/>
        </a>
      </div>
    </div>
    <p v-if="!canUserDeposit" class="mb-3">
      Unfortunately you are unable to deposit at this time.
    </p>
    <p v-if="errorMessage" class="text-red mb-2">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useLocationStore } from '@/store/location';
import { useBankingStore } from '~/store/banking';
import { useBlockchainConfigStore } from '@/store/blockchainConfig';

const props = defineProps({
  errorMessage: {
    type: String,
    default: null,
  },
  txHash: {
    type: String,
    default: null,
  },
  network: {
    type: Object,
    default: null,
  },
  canUserDeposit: {
    type: Boolean,
    default: false,
  },
  isDepositProcessing: {
    type: Boolean,
    default: false,
  },
});

const blockchainConfigStore = useBlockchainConfigStore();
const {
  getExplorerUrl,
} = blockchainConfigStore;

const bankingStore = useBankingStore();
const {
  showDepositCompleted,
  swapsPending,
} = storeToRefs(bankingStore);

const locationStore = useLocationStore();
const {
  isDepositRestricted,
} = storeToRefs(locationStore);

const txUrl = computed(() => props.txHash ? getExplorerUrl(props.network?.name, 'transaction', props.txHash) : null);
</script>
