<template>
    <div>
        <div class="relative mb-4 flex flex-wrap items-stretch">
            <div class="relative flex-auto">
                <input
                    v-if="!currentCode"
                    v-model="newCode"
                    class="w-full relative bg-slate-900 border-slate-900 rounded-l-md py-3 px-4 border-2 outline-none focus:border-white/40 focus:ring-0 autofill:bg-slate-900 transition-all disabled:text-slate-100"
                    type="text"
                    :disabled="loading"
                    placeholder="Promo Code (Optional)"
                    maxlength="30"
                />
                <div
                    v-else
                    class="flex items-center w-full relative bg-slate-900 border-slate-900 rounded-l-md py-3 px-4 border-2"
                >
                    {{ currentCode }}
                    <span class="icon-ico-check-circle text-green ml-2"></span>
                </div>
                <div
                    v-if="currentCode"
                    class="absolute right-3 top-0 bottom-0 flex items-center animate-fade-in text-white font-bold text-xl"
                    v-tippy="{ content: tip, placement: 'left' }">
                    <span class="icon-ico-info"></span>
                </div>
                <div
                    v-if="error"
                    class="absolute right-3 top-0 bottom-0 flex items-center animate-fade-in text-red"
                    v-tippy="{ content: error, placement: 'left' }">
                    <span class="icon-ico-x"></span>
                </div>
            </div>
            <ButtonButton
                v-if="!currentCode"
                class="rounded-l-none text-xl flex items-center justify-center"
                size="xs"
                :disabled="!newCode"
                :is-loading="loading"
                @click="applyCode"
            >
                <span
                    class="icon-ico-tick transition-all"
                    :class="{ 'opacity-0': loading }"></span>
            </ButtonButton>
            <ButtonButton
                v-else
                class="rounded-l-none text-lg flex items-center justify-center"
                size="xs"
                theme="grey"
                :is-loading="loading"
                @click="removeCode"
            >
                <span
                    class="icon-ico-x transition-all"
                    :class="{ 'opacity-0': loading }"></span>
            </ButtonButton>
        </div>
    </div>
</template>

<script setup>
const { $api } = useNuxtApp();

const props = defineProps({
        source: {
        type: String,
        required: true,
    }, 
    code: {
        type: String,
        default: null,
    }
});

const currentCode = ref(null);
const newCode = ref(null);
const loading = ref(true);
const error = ref(null);
const tip = computed(() => `NOTE: This code will be removed from your account 2 hours after it was applied. Your next ${props.source.toLowerCase()} needs to be completed within this time for it to take effect.`);

async function getAppliedCode() {
    try {
        currentCode.value = await $api(`reward/code/${props.source}`, { method: 'GET' });
    } catch {
    } finally {
        loading.value = false;
    }
}

async function applyCode() {
    loading.value = true;

    try {
        await $api('reward/code', {
            method: 'POST',
            body: {
                source: props.source,
                code: newCode.value
            }
        });
        currentCode.value = newCode.value;
        newCode.value = null;
    } catch (err) {
        error.value = err.response?._data?.message || err.message;
    } finally {
        loading.value = false;
    }
}

async function removeCode() {
    loading.value = true;

    try {
        await $api(`reward/code/${props.source}`, {
            method: 'DELETE'
        });
        newCode.value = null;
        currentCode.value = null;
    } catch (err) {
        error.value = err.response?._data?.message || err.message;
    } finally {
        loading.value = false;
    }
}

onMounted(async () => {
    await getAppliedCode();

    if (props.code && currentCode.value !== props.code) {
        if (currentCode.value) {
            await removeCode();
        }
        newCode.value = props.code;
        await applyCode();
        return;
    }
});

watch(() => newCode.value, () => {
    error.value = null;
});
</script>